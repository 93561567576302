<template>
  <div>
    <DataGridFactory
      :dataSource="store.DocFiscais.table"
      :enabledActions="false"
      :nameToExport="'Documentos Fiscais'"
    >
      <template v-slot:column>
        <DxColumn caption="Doc" type="buttons" fixed-position="left">
          <DxButton
            hint="Ver Documento PDF"
            icon="mdi mdi-file-pdf"
            :on-click="PreviewDocument"
          />
        </DxColumn>
        <DxColumn data-field="modelo" alignment="right" />
        <DxColumn data-field="serie" alignment="right" caption="Série" />
        <DxColumn data-field="numero" alignment="right" caption="Número"  />
        <DxColumn
          data-field="chave"
          caption="Chave de Acesso"
          alignment="center"
        />
        <DxColumn data-field="emissao" caption="Emissão" data-type="date"  />
        <DxColumn
          data-field="tipoDocumento"
          alignment="center"
          caption="Tipo"
          :calculate-cell-value="(rowData) => {return Validators.formataTipo(rowData.tipoDocumento)}"
        />
        <DxColumn
          data-field="finalidade"
          alignment="center"
          caption="Finalidade"
          :calculate-cell-value="(rowData) => { return Validators.formataFinalidade(rowData.finalidade)}"
        />
        <DxColumn
          data-field="valor"
          data-type="number"
          caption="Valor"
          fixed-position="right"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
      </template>

      <template v-slot:footer>
        <DxExport :enabled="true" />

        <DxSummary>
          <DxTotalItem
            column="modelo"
            alignment="left"
            summary-type="count"
            display-format="{0} Registros"
         />
         <DxTotalItem
            column="valor"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
         />
        </DxSummary>
      </template>
    </DataGridFactory>

    <DxLoadPanel
      :visible="state.isLoading"
      :show-pane="true"
      :shading="true"
      shading-color="rgba(0, 0, 0, 0.2)"
    />
  </div>
</template>
<script lang="ts" src="./index.ts"></script>