import { DxLoadPanel } from 'devextreme-vue/ui/load-panel';
import { documentoStore } from './../../../store/modules/documentos/index';
import { DxColumn, DxExport, DxSummary, DxTotalItem, DxButton } from 'devextreme-vue/ui/data-grid';
import { defineComponent, reactive } from 'vue';
import DataGridFactory from '../../datagrid-factory/index.vue'
import  Validators from '../../../helpers/validators-input';

export default defineComponent({
  name: 'DocumentosFiscais',
  setup() {
    const store = documentoStore()
    const state = reactive({
      isLoading: false,
    })

    async function PreviewDocument(e: any) {
      const chaveAcesso = e.row.data.chave
      const modelo = e.row.data.modelo;
      const numeroDoc = e.row.data.numero
      let newWindow: any;

      state.isLoading = true
      if (modelo === 55) {
        const pathNfe = await store.GET_PREVIEW_DANFE(chaveAcesso);

        if (pathNfe) {
          state.isLoading = false;
          newWindow = window.open("url", '_blank', "toolbar=yes,scrollbars=yes,resizable=yes")

          newWindow?.document.write(
            `
            <a id="link" href="data:application/pdf;base64,${encodeURI(pathNfe)}" style="visibility: hidden;" download="Nfe_${numeroDoc}"></a>

            <iframe width="100%" height="100%" src="data:application/pdf;base64,${encodeURI(pathNfe)}"></iframe> 
            `
          )
          newWindow.document.getElementById("link").click();
        }
      } else {
        const pathCe = await store.GET_PREVIEW_DANFE_CTE(chaveAcesso)

        if (pathCe) {
          state.isLoading = false;
          newWindow = window.open("url", '_blank', "toolbar=yes,scrollbars=yes,resizable=yes")
          newWindow?.document.write(pathCe)
        }
      }
    }

    return {
      store,
      state,
      PreviewDocument,
      Validators
    }
  },
  components: {
    DataGridFactory,
    DxColumn,
    DxExport,
    DxSummary,
    DxTotalItem,
    DxButton,
    DxLoadPanel
  }
})